<template style="background-color: white">
  <div id="app-resource-category" class="app-add">
    <loading :loading="loading" text="提交中"></loading>
    <el-form ref="form" :model="category" :rules="rules" label-width="100px">
      <el-form-item label="分类名称" prop="name">
        <el-input v-model="category.name" placeholder = "请输入"></el-input>
      </el-form-item>
      <el-form-item style="display: none">
        <el-button @click="submit('form')" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {UrlEnum,TipEnum} from "../../../../public/js/common-vue";
import {getLocalProjectId} from "../../../../public/js/common-vue";
import Loading from "@/components/Loading";
export default {
  components: {Loading},
  data() {
    return {
      category:{
        name:'',
        projectId:getLocalProjectId()
      },
      name: "Add.vue",
      rules:{
        name:[{required: true, message:'请输入', trigger:'blur'}]
      },
      loading: false
    }
  },
  methods: {
    submit: function (formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http({
            method: "post",
            url: UrlEnum.RESOURCE_CATEGORIES,
            data: this.category,
          })
          .then((res) => {
            this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
            setTimeout(function () {
              window.parent.postMessage({
                msg: '',
                key: 'refresh'
              }, '*')
            }, 800);
          })
          .catch((res) => {
            this.loading = false;
            if(res.response != null){
              const data = res.response.data;
              this.showMsgError(data.message);
            }
          });
        }
      })
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
@import "../../../../static/css/add.css";
</style>
